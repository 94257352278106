import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import router from './routes/router';
import VueYoutube from 'vue-youtube';
import VTooltip from 'v-tooltip'
import "font-awesome/css/font-awesome.min.css";

// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import { initializeApp } from 'firebase/app';

// TODO: Replace the following with your app's Firebase project configuration
// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
const firebaseConfig = {
    apiKey: "AIzaSyDC_lvkPRXL-WKF1OvXq0WWM3x-cbPLz40",
    authDomain: "fessetoute-webapp.firebaseapp.com",
    projectId: "fessetoute-webapp",
    storageBucket: "fessetoute-webapp.appspot.com",
    messagingSenderId: "399144542512",
    appId: "1:399144542512:web:9b0d43afee1b0dd33de8f5",
    measurementId: "G-XH3JPNWL7R"
};
  
  // Initialize Firebase
  initializeApp(firebaseConfig);


Vue.use(VueRouter);
Vue.use(VueYoutube);
Vue.use(VTooltip);
Vue.component('VueFontawesome', require('vue-fontawesome-icon/VueFontawesome.vue').default);


new Vue({
    router: router,
    render: h => h(App),
}).$mount('#app')
