<template>
<div class="navbar-element" @click="goTo()" :class="{ 'active' : path === $route.name}">
    <span v-if="label !== ''">{{label}}</span>
    <span v-if="icon !== ''"><vue-fontawesome :icon="icon" size="1" color="black"></vue-fontawesome></span>
</div>
</template>

<script>
import urls from '../../utils/links.utils';

export default {
    props: {
        label: {
            type: String,
            default: ""
        },
        icon: {
            type: String,
            default: ""
        },
        dest: {
            type: String
        },
        path: {
            type: String,
            default: ""
        }
    },
    methods: {
        goTo() {
            if (this.dest === "home") {
                this.$router.push('accueil');
            } else if (this.dest === "music") {
                window.open(urls.music, '_blank');
            } else if (this.dest === "video") {
                window.open(urls.videos, '_blank');
            } else if (this.dest === "events") {
                window.open(urls.events, '_blank');
            } else if (this.dest === "merch") {
                window.open(urls.merch, '_blank');
            } else if (this.dest === "facebook") {
                window.open(urls.facebook, '_blank');
            } else if (this.dest === "instagram") {
                window.open(urls.instagram, '_blank');
            } else if (this.dest === "booking") {
                this.$router.push('contact-booking');
            }
        }
    }
}
</script>

<style scoped lang="scss">
.navbar-element {
    height: $navbar-height;
    cursor: pointer;
    text-align: center;
    padding: 0 14px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $font-color-main;
    transition: all 0.5s;
    font-family: $font-family-title;
    position: relative;

    &.active:after {
        width: 100%
    }

    &:after, &:before {
        transition: all .5s;
    }

    &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 0%;
        content: '.';
        color: transparent;
        background: $font-color-main;
        height: 1px;
    }

    &:hover {
        &:after {
            width: 100%;
        }
    }
}
</style>