import VueRouter from 'vue-router';
import routes from './routes';

const router = new VueRouter({
    routes,
    linkActiveClass: 'active'
});

router.beforeEach(async (to, from, next) => {
    document.title = to.meta.label;
    next();
});

export default router;