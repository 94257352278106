<template>
<div class="page-home">
    <news-block :opened="opened" :show-content="showContent" :change-opened-status="changeOpenedStatus"/>
    <floating-button icon="flag-checkered" :action="()=>{changeOpenedStatus()}" tooltip="Nouvelles"/>
</div>
</template>

<script>
import NewsBlock from './NewsBlock';
import FloatingButton from '../../components/floating-button/FloatingButton';

export default {
    components: {
        NewsBlock,
        FloatingButton
    },
    created() {
        this.changeOpenedStatus(true);
    },
    data() {
        return {
            opened: false,
            showContent: false,
        }
    },
    methods: {
        changeOpenedStatus(newValue) {
            if (newValue !== undefined && newValue !== null) {
                this.opened = newValue;
            } else {
                this.opened = !this.opened;
            }
            if (this.opened) {
                setTimeout(() => { 
                    this.showContent = true;
                }, 1000);
            } else {
                this.showContent = false;
            }
        }
    },
}
</script>

<style scoped lang="scss">
.page-home {
    display: flex;
    justify-content: center;
    margin: 10px 0;
    box-sizing: content-box;
}
</style>