<template>
<div class="off-canva-menu" :class="{'displayed': display}" v-click-outside="closeMenu">
    <div v-for="link in links" :key="link.label" class="off-canva-menu-element" :class="{ 'active' : link.path === $route.name}" @click="link.action()">
        {{link.label}}
    </div>
</div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import urls from '../../utils/links.utils';

export default {
    props: {
        display: {
            default: false,
            type: Boolean
        },
        changeDisplay: {
            type: Function
        }
    },
    data() {
        return {
            links: [
                {
                    label: "Accueil",
                    path: "accueil",
                    action: () => { this.$router.push('accueil'); this.closeMenu(); }
                },
                {
                    label: "Musique",
                    action: () => { window.open(urls.music, '_blank'); }
                },
                {
                    label: "Vidéoclips",
                    action: () => { window.open(urls.videos, '_blank'); }
                },
                {
                    label: "Évènements",
                    path: "evenements",
                    action: () => { window.open(urls.events, '_blank'); }
                },
                {
                    label: "Merch",
                    action: () => { window.open(urls.merch, '_blank'); }
                },
                {
                    label: "Contact/Booking",
                    path: "contact-booking",
                    action: () => { this.$router.push('contact-booking'); this.closeMenu(); }
                },
                {
                    label: "Facebook",
                    action: () => { window.open(urls.facebook, '_blank'); }
                },
                {
                    label: "Instagram",
                    action: () => { window.open(urls.instagram, '_blank'); }
                },
            ]
        };
    },
    methods: {
        closeMenu() {
            if (this.display === true) {
                this.changeDisplay(false);
            }
        },
    },
    directives: {
        ClickOutside
    }
}
</script>

<style scoped lang="scss">
$off-canva-width: 220px;
$element-padding: 15px;

.off-canva-menu {
    width: $off-canva-width;
    background-color: $background-main;
    height: 100vh;
    z-index: 3;
    position: absolute;
    left: calc(#{$off-canva-width} * -1);
    top: 0;
    transition: left .5s ease-in-out, box-shadow .5s ease-in-out;
    

    &.displayed {
        left: 0;
        box-shadow: 0 0 10px black;
    }

    &-element {
        font-family: $font-family-main;
        width: auto;
        text-align: end;
        cursor: pointer;
        padding: $element-padding calc(#{$element-padding} * 2);
        font-size: large;

        &:first-child {
            margin-top: $element-padding;
        }

        &.active {
            color: $font-color-accent;
        }
    }
}
</style>