<template>
<div class="default-layout">
    <navigation></navigation>
    <router-view></router-view>
</div>
</template>

<script>
import NavBar from "../components/navbar/NavBar";

export default {
    components: {
        navigation: NavBar
    }
}
</script>

<style lang="scss">
</style>