const facebook = "https://www.facebook.com/fessetoute"
const instagram = "https://www.instagram.com/fessetoute";
const music = "https://fessetoute.bandcamp.com";
const merch = "https://fessetoute.bandcamp.com/merch";
const videos = "https://www.youtube.com/channel/UCnY-9jZEPXghNMKJzAfkb2w/featured";
const events = "https://www.facebook.com/fessetoute/events";

export default {
    facebook,
    instagram,
    music,
    merch,
    videos,
    events
};