<template>
<div class="news-block" :class="{opened: opened}">
    <div v-if="showContent" class="close-icon" @click="changeOpenedStatus(false)">
        <vue-fontawesome icon="times" size="2" color="white"></vue-fontawesome>
    </div>
    <div v-if="showContent">
        <news-article v-for="(article, index) in articles" :key="index" :article="article"/>
    </div>
</div>
</template>

<script>
import NewsArticle from './Article';

export default {
    props: {
        changeOpenedStatus: {
            type: Function
        },
        opened: {
            type: Boolean
        },
        showContent: {
            type: Boolean
        }
    },
    components: {
        NewsArticle
    },
    data() {
        return {
            articles: [
                {
                    title: "Sortie de l'album Streetbang!",
                    image: "streetbang.jpg",
                    url: "https://bit.ly/3nMbYyV",
                    text: "Notre premier album est disponible sur toutes tes plateformes de streaming préférées! Juste à cliquer sur la pochette pour avoir tous les liens!"
                },
                {
                    title: "Live session alert!!",
                    text: "Live session au studio La Cabane!",
                    videoId: "uAjj7lAaMqI"
                },
                {
                    title: "Premier épisode de jour de show!",
                    text: "La toute nouvelle websérie branchée",
                    videoId: "fvRO-C9xLpE"
                },
                {
                    title: "Le clip de Kung Fury est sorti!",
                    text: "À voir sur YouTube!",
                    videoId: "yI9cGwACcCA"
                },
                {
                    title: "Vidéoclip pour le single Streetbang!",
                    text: "Allez regarder sur YouTube!",
                    videoId: "yVP4IT0UmYo"
                }
            ]
        };
    }
}
</script>

<style scoped lang="scss">
$transition-time: 1s;
$transition-time-style: $transition-time ease-in-out;

.all-caps {
    text-transform: uppercase;
}

.news-block {
    background-color: rgba(0,0,0,0.95);
    border-radius: 5px;
    box-shadow: 0 0 10px black;
    position: relative;
    height: 0;
    width: 0;
    padding: 0;
    color: white;
    font-family: $font-family-title;
    transition: all $transition-time-style;

    &.opened {
        width: 80%;
        height: fit-content;
        padding: 30px;

        @media screen and (min-width: $sm) {
            width: 75%;
        }

        @media screen and (min-width: $md) {
            width: 75%;
        }

        @media screen and (min-width: $lg) {
            width: 70%;
        }

        @media screen and (min-width: $xl) {
            width: 65%;
        }

        @media screen and (min-width: $xxl) {
            width: 60%;
        }
    }

    .close-icon {
        right: 0;
        top: 0;
        margin: 15px;
        position: absolute;
        cursor: pointer;
    }

    .new-button {
        cursor: pointer;
    }
    
}
</style>