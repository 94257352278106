<template>
<div>
    <h1 v-if="article.title">{{article.title}}</h1>
    <div v-if="article.videoId" class="video">
        <div class="iframe">
            <youtube :video-id="article.videoId" :resize="true" :fit-parent="true"></youtube>
        </div>
    </div>
    <div v-if="article.image" class="image">
        <img :src="getImgUrl(article.image)" @click="oppenLink(article.url)"/>
    </div>
    <p v-if="article.text">{{article.text}}</p>
    <hr/>
</div>
</template>

<script>
export default {
    props: {
        article: {
            type: Object
        }
    },
    methods: {
        getImgUrl(fileName) {
            return require('../../assets/' + fileName)
        },
        oppenLink(link) {
            window.open(link, '_blank');
        }
    }
}
</script>

<style scoped lang="scss">
h1 {
    font-family: $font-family-title;
}

.video .iframe {
    width: 100%;
    max-width: 650px;
}

.video, .image {
    width: 100%;
    display: flex;
    justify-content: center;
}

.image img {
    width: 100%;
    max-width: 450px;
    cursor: pointer;
}

p {
    font-family: $font-family-main;
    text-align: justify;
    text-justify: inter-word;
}

hr {
    color: $background-main;
}
</style>