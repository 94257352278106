<template>
<div class="navbar">
    <div class="main-logo">
        <img src="../../assets/logo_rotated.png"/>
    </div>
    <div class="navbar-top">
        <div class="navbar-top-container">
            <img class="logo" src="../../assets/logo_rotated.png"/>
            <div class="navbar-top-container-elements">
                <nav-bar-element label="Accueil" dest="home" path="accueil"/>
                <nav-bar-element label="Musique" dest="music"/>
                <nav-bar-element label="Vidéoclips" dest="video"/>
                <nav-bar-element label="Évènements" dest="events" path="evenements"/>
                <nav-bar-element label="Merch" dest="merch"/>
                <nav-bar-element label="Contact/Booking" dest="booking" path="contact-booking"/>
                <nav-bar-element icon="facebook-f" dest="facebook"/>
                <nav-bar-element icon="instagram" dest="instagram"/>
            </div>
            <div class="hamburger" @click="changeOffCanvaMenuDisplay()">
                <img class="hamburger-icon" src="../../assets/logo_min_black.png"/>
            </div>
        </div>
    </div>
    <off-canva-menu :display="displayOffCanvaMenu" :change-display="changeOffCanvaMenuDisplay"/>
</div>
</template>

<script>
import NavBarElement from "./NavBarElement";
import OffCanvaMenu from './OffCanvaMenu';

export default {
    components: {
        NavBarElement,
        OffCanvaMenu
    },
    data() {
        return {
            displayOffCanvaMenu: false
        }
    },
    methods: {
        changeOffCanvaMenuDisplay(newValue) {
            if (newValue !== undefined && newValue !== null) {
                this.displayOffCanvaMenu = newValue;
            } else {
                this.displayOffCanvaMenu = !this.displayOffCanvaMenu;
            }
        }
    }
}
</script>

<style lang="scss">
$logo-margin: 6px;
$main-logo-height: 150px;
$main-logo-margin-vertical: 10px;

.main-logo {
    margin: $main-logo-margin-vertical 0;
    display: flex;
    justify-content: center;
    @media screen and (max-width: $lg) {
            display: none;
        }
    img {
        height: $main-logo-height;
    }
}


.navbar-top {
    width: 100%;
    background: none;
    height: $navbar-height;
    transition: background-color 0.3s ease-out, box-shadow 0.3s ease-out;
    z-index: 2;

    &-container {
        height: $navbar-height;
        width: 100%;

        &-elements {
            display: none;
            justify-content: center;
            position: relative;

            @media screen and (min-width: $lg) {
                display: flex;
            }
        }
    }

    .logo {
        position: absolute;
        height: calc(#{$navbar-height} - #{$logo-margin} - #{$logo-margin});
        top: $logo-margin;
        right: calc(#{$logo-margin} * 2);
        @media screen and (min-width: $lg) {
            display: none;
        }
    }

    .hamburger {
        position: absolute;
        left: calc(#{$logo-margin} * 2);
        top: $logo-margin;
        cursor: pointer;
        @media screen and (min-width: $lg) {
            display: none;
        }

        &-icon {
            height: 32px;
        }
    }
}
</style>