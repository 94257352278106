import PageHome from '../pages/home/PageHome';
// import PageEvents from '../pages/events/PageEvents';
import PageContact from '../pages/contact/PageContact';
import DefaultLayout from '../layout/DefaultLayout';

const routes = [
  {
    path: '/',
    name: 'default',
    redirect: '/accueil',
    component: DefaultLayout,
    children: [
        {
            path: 'accueil',
            name: 'accueil',
            component: PageHome,
            meta: {
                label: 'Accueil - Fesse Toute'
            },
        },
        // {
        //     path: 'evenements',
        //     name: 'evenements',
        //     component: PageEvents,
        //     meta: {
        //         label: 'Évènements - Fesse Toute'
        //     },
        // },
        {
            path: 'contact-booking',
            name: 'contact-booking',
            component: PageContact,
            meta: {
                label: 'Contact/Booking - Fesse Toute'
            },
        }
      ]
  },
  {
      path: '*',
      redirect: '/accueil',
  },
];

export default routes;