<template>
<div class="page-contacts">
    <div class="contacts">
        <h1>Contact/Booking</h1>
        <a class="contact" href="mailto:fessetoute@gmail.com">fessetoute@gmail.com</a>
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
.page-contacts {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 10px 0;
    box-sizing: content-box;
}
.contacts {
    width: 80%;
    height: fit-content;
    padding: 25px;
    background-color: rgba(0,0,0,0.95);
    border-radius: 5px;
    box-shadow: 0 0 10px black;
    color: white;
    font-family: $font-family-title;
    margin-bottom: 10px;

    @media screen and (min-width: $sm) {
        width: 75%;
    }

    @media screen and (min-width: $md) {
        width: 75%;
    }

    @media screen and (min-width: $lg) {
        width: 70%;
    }

    @media screen and (min-width: $xl) {
        width: 65%;
    }

    @media screen and (min-width: $xxl) {
        width: 60%;
    }

    .contact {
        color: $font-color-accent;
    }
}
</style>