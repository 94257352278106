<template>
    <router-view></router-view>
</template>

<script>
export default {}
</script>

<style lang="scss">
.tooltip {
    font-family: $font-family-main;
}
body {
    margin: 0 !important;
    background-image: url("./assets/granny-background.jpeg");
    background-position-x: center;
    background-position-y: top;
    background-size: cover;
    background-attachment: fixed;
}
</style>
