<template>
<button class="floating" @click="action" v-tooltip.left="tooltip">
    <vue-fontawesome :icon="icon" size="1" color="#F0F0F0"></vue-fontawesome>
</button>
</template>

<script>
export default {
    props: {
        icon: {
            type: String
        },
        action: {
            type: Function
        },
        tooltip: {
            type: String
        }
    }
}
</script>

<style scoped lang="scss">
.floating {
    border-radius: 50%;
    border-width: 0px;
    height: 50px;
    width: 50px;
    right: 20px;
    bottom: 30px;
    position: fixed;
    box-shadow: 0 6px 10px 0;
    cursor: pointer;
    transition: box-shadow 0.2s;
    background-color: $font-color-accent;
    outline: none;

    &:hover {
        box-shadow: 0 4px 10px 0;
    }
}
</style>